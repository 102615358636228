.button-primary, .button-secondary {
  border-radius: 40px;
  padding: .8em 2em;
  font-family: Roboto, sans-serif;
  font-size: clamp(16px, 1rem + .3571vw - 1.14272px, 20px);
  line-height: normal;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.button-secondary {
  color: #ff5722;
}

.button-secondary:hover {
  filter: brightness(1.2);
  background-color: #ff742133;
}

.button-secondary:active {
  filter: brightness(.6);
}

.button-primary {
  color: #fff;
  background-color: #ff5722;
  border: none;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.button-primary:hover {
  filter: brightness(1.6);
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.button-primary:active {
  filter: brightness(.6);
}

.onoffswitch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 100px;
  margin: 7vh auto 10vh;
  position: relative;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  cursor: pointer;
  border: 2px solid #0000;
  border-radius: 20px;
  transition: all .3s ease-out;
  display: block;
  overflow: hidden;
}

.onoffswitch-label:hover {
  border: 2px solid #ff5722;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  box-sizing: border-box;
  width: 50%;
  height: 30px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  display: block;
}

.onoffswitch-inner:before {
  content: "it";
  color: #fff;
  margin-left: 0;
}

.onoffswitch-inner:after {
  content: "en";
  color: #fff;
}

#lang-switch + .onoffswitch-label .onoffswitch-inner {
  width: 100%;
  margin-left: 0;
  display: block;
}

.onoffswitch-switch {
  background: #262626;
  border-radius: 20px;
  width: 50px;
  margin: 0;
  transition: all .3s ease-out;
  display: block;
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 48px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 2px;
}

@media screen and (width >= 768px) {
  .onoffswitch {
    margin: 0;
  }
}

:root {
  --swiper-theme-color: #ff5722;
  --swiper-pagination-bullet-inactive-color: #ff5722;
  --swiper-pagination-bullet-inactive-opacity: .3;
  --swiper-pagination-top: 10px;
  --swiper-pagination-bottom: auto;
  --swiper-navigation-sides-offset: 1rem;
  --swiper-navigation-top-offset: 44%;
}

.swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
}

@media screen and (width >= 768px) {
  :root {
    --swiper-navigation-top-offset: 50%;
  }
}

.swiper {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  max-width: 60ch;
  height: auto;
  padding: 2.5rem 0;
  overflow: visible;
}

.swiper-slide {
  box-sizing: border-box;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.swiper-slide.swiper-slide-active {
  box-shadow: 0 0 100px #08445e99;
}

.no-break {
  white-space: nowrap;
}

:root {
  scroll-behavior: smooth;
  font-family: Roboto, sans-serif;
  font-size: clamp(16px, 1rem + .3571vw - 1.14272px, 20px);
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #ccc;
  background-color: #000;
  min-width: 320px;
  min-height: 0;
}

h2 {
  text-align: center;
  letter-spacing: -.01em;
  margin-bottom: 3rem;
}

h3 {
  letter-spacing: .02em;
  font-size: 1.3em;
}

p {
  line-height: 1.5;
}

#close-menu-btn {
  color: #fff;
  align-self: flex-end;
  margin: 1rem;
  font-size: 2.5rem;
}

.nav {
  color: #fff;
  z-index: 3;
  background-color: #000000f0;
  flex-direction: column;
  width: 100%;
  transition: transform .2s;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(110%);
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.nav .nav-links {
  text-align: center;
  flex-direction: column;
  list-style-type: none;
  display: flex;
}

.nav .nav-links :first-child {
  order: 1;
}

.nav a {
  text-transform: uppercase;
  color: #fff;
  padding: 1.5rem 3rem;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.show-menu {
  transform: translateX(0%);
}

.section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header {
  color: #fff;
  background-color: #000;
  background-image: linear-gradient(#00000080, #00000080), url("header.c3ef286b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  min-height: 100vh;
}

.banner {
  margin: auto;
  padding: 1rem;
}

.icons {
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 10vw;
  width: fit-content;
  margin: auto;
  font-size: 2.2em;
  display: grid;
}

.icon-container:nth-child(n+5) .banner-tooltip {
  top: 110%;
  bottom: unset;
  left: 50%;
}

svg {
  fill: #fff;
  width: 1.125em;
  height: 1.125em;
  transition: fill .3s;
}

svg:hover {
  fill: #ff5722;
}

.icon-container {
  position: relative;
}

.icon-container .banner-tooltip {
  white-space: nowrap;
  color: #ff5722;
  text-shadow: 0 0 10px #000;
  text-align: center;
  z-index: 1;
  opacity: 0;
  cursor: default;
  padding: 5px;
  font-family: Roboto, sans-serif;
  font-size: .5em;
  font-style: italic;
  font-weight: 500;
  transition: opacity .3s;
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
}

.icon-container svg:hover ~ .banner-tooltip {
  opacity: 1;
}

.title-container {
  justify-content: center;
  display: flex;
}

.title {
  letter-spacing: -.02em;
  margin-bottom: 10vh;
  font-family: Roboto, sans-serif;
  font-size: clamp(1.4rem, .7297rem + 3.3514vw, 4.5rem);
  line-height: 121%;
}

.title .title-span {
  color: #ff5722;
}

.link-text {
  color: #fff;
}

.link-text:hover {
  color: #ff5722;
}

.link-text:active {
  filter: brightness(.6);
}

#open-menu-btn {
  margin: 1rem;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.about {
  justify-content: space-evenly;
  margin: 2rem 0;
  padding: 2rem 1rem;
}

.about .about-title {
  flex-shrink: 0;
}

.about .about-text-card {
  background-color: #262626;
  border-radius: 20px;
  max-width: 60ch;
  padding: 1rem;
  line-height: normal;
  box-shadow: 0 0 100px #08445e99;
}

.about .about-links {
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

.projects {
  gap: 4rem;
  margin: 8rem 0;
  padding: 2rem 1rem 1rem;
  display: block;
  overflow: hidden;
}

.project-card {
  background-color: #262626;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  display: flex;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.project-card picture {
  height: 250px;
  overflow: hidden;
}

.project-card picture img {
  border-radius: 20px;
}

.project-card .project-technology-list {
  border-radius: 9999px;
  flex-wrap: wrap;
  gap: .5rem;
  width: 100%;
  padding: 1rem 2rem;
  font-size: .75rem;
  display: flex;
}

.project-card .project-technology-list span {
  color: #ff5722;
  background: #ff57221a;
  border-radius: 9999px;
  padding: .25rem .5rem;
}

.project-card .project-screenshot-container {
  width: 100%;
  max-height: 150px;
  margin: 0;
  overflow: hidden;
}

.project-card .project-screenshot {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.project-card .project-title {
  text-align: center;
  padding: 1rem 3.5rem;
}

.project-card .project-description {
  padding: 1rem;
}

.project-card .project-links {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding: 1rem;
  display: flex;
}

.contact {
  text-align: center;
  background: linear-gradient(#000 0%, #084560 50% 100%);
  margin: 6rem 0 0;
  padding: 2rem 1rem;
}

.contact input, .contact textarea {
  font-family: Roboto, sans-serif;
  font-size: inherit;
  color: #ccc;
  background-color: #262626;
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  margin-bottom: .5rem;
  padding: .5rem;
}

@media screen and (width >= 425px) {
  .title {
    font-size: clamp(1.7rem, 1.2027rem + 2.4865vw, 4rem);
  }
}

@media screen and (width >= 768px) {
  #open-menu-btn, #close-menu-btn {
    display: none;
  }

  .nav {
    text-shadow: 0 0 20px #000;
    box-shadow: none;
    background: linear-gradient(#000000e6 0% 70%, #0000 100%);
    position: absolute;
    transform: translateX(0%);
  }

  .nav .nav-links {
    flex-direction: row;
    justify-content: end;
    align-items: start;
    gap: min(2vw, 1rem);
    margin: 0 2vw;
  }

  .nav .nav-links :first-child {
    order: -1;
    margin: auto auto auto 0;
  }

  .nav a {
    padding: 1.5rem 2vw;
  }

  .icons {
    grid-template-columns: repeat(8, 1fr);
    gap: 4vw;
  }

  .icon-container:nth-child(n+5) .banner-tooltip {
    top: unset;
    bottom: 110%;
  }

  .about .about-text-card {
    padding: 2rem;
  }

  .project-card .project-description {
    padding: 1rem 2rem;
  }

  .project-card .project-screenshot-container {
    max-height: 250px;
  }

  .project-card .project-links {
    padding: 1rem 2rem 2rem;
  }
}

@media screen and (width >= 992px) {
  .about {
    flex-direction: row;
    justify-content: center;
    gap: 5%;
  }

  .about .about-links {
    gap: 1.5rem;
    margin: 0;
  }
}
/*# sourceMappingURL=index.08b390ed.css.map */
