:root {
	--swiper-theme-color: #{$accent-1};
	--swiper-pagination-bullet-inactive-color: #{$accent-1};
	--swiper-pagination-bullet-inactive-opacity: 0.3;
	--swiper-pagination-top: 10px;
	--swiper-pagination-bottom: auto;
	--swiper-navigation-sides-offset: 1rem;
	--swiper-navigation-top-offset: 44%;
}

.swiper-pagination-bullet-active {
	--swiper-pagination-bullet-width: 10px;
	--swiper-pagination-bullet-height: 10px;
}

@media screen and (min-width: 768px) {
	:root {
		--swiper-navigation-top-offset: 50%;
	}
}

.swiper {
	user-select: none;
	box-sizing: border-box;
	overflow: visible;
	width: 100%;
	height: auto;
	max-width: 60ch;
	padding: 2.5rem 0;
}

.swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	border-radius: 20px;

	&.swiper-slide-active {
		box-shadow: $shadow-card;
	}
}
