@import "variables.scss";
@import "buttons.scss";
@import "lang-switcher.scss";
@import "swiper.scss";

.no-break {
	white-space: nowrap;
}

:root {
	scroll-behavior: smooth;
	font-family: $main-font-family;
	font-size: $main-font-size;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	min-height: 0vw; //safari font size calc fix
	min-width: 320px;
	background-color: $background-dark;
	color: $color-dark;
}

h2 {
	margin-bottom: 3rem;
	text-align: center;
	letter-spacing: -0.01em;
}

h3 {
	font-size: 1.3em;
	letter-spacing: 0.02em;
}

p {
	line-height: 1.5;
}

#close-menu-btn {
	margin: 1rem;
	font-size: 2.5rem;
	color: white;
	align-self: flex-end;
}

.nav {
	display: flex;
	width: 100%;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	box-shadow: $shadow-z16;
	color: white;
	background-color: rgba(0, 0, 0, 94%);
	transform: translateX(110%);
	transition: transform 0.2s ease;
	z-index: 3;
	.nav-links {
		display: flex;
		flex-direction: column;
		list-style-type: none;
		text-align: center;
		:nth-child(1) {
			order: 1;
		}
	}
	a {
		display: block;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 1.2rem;
		color: white;
		padding: 1.5rem 3rem;
		transition: all 0.3s;
	}
}

.show-menu {
	transform: translateX(0%);
}

.section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.header {
	color: white;
	min-height: 100vh;
	background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), rgba(0, 0, 0, 0.5)),
		url(../media/img/header.jpg);
	background-color: black;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	justify-content: space-between;
}

.banner {
	margin: auto;
	padding: 1rem;
}

.icons {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	font-size: 2.2em;
	justify-items: center;
	margin: auto;
	width: fit-content;
	gap: 10vw;
}

.icon-container:nth-child(1n + 5) .banner-tooltip {
	top: 110%;
	bottom: unset;
	left: 50%;
}

svg {
	width: 1.125em;
	height: 1.125em;
	fill: white;
	transition: fill 0.3s;
}
svg:hover {
	fill: $accent-1;
}

.icon-container {
	position: relative;

	.banner-tooltip {
		font-size: 0.5em;
		font-family: $main-font-family;
		font-style: italic;
		font-weight: 500;
		white-space: nowrap;
		color: $accent-1;
		text-shadow: 0px 0px 10px #000000;
		text-align: center;
		padding: 5px 5px;
		position: absolute;
		z-index: 1;
		bottom: 110%;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s;
		cursor: default;
	}
	svg:hover ~ .banner-tooltip {
		opacity: 1;
	}
}

.title-container {
	display: flex;
	justify-content: center;
}

.title {
	margin-bottom: 10vh;
	font-family: $main-font-family;
	// 1.4rem@320px, 4rem@1800px
	font-size: clamp(1.4rem, 0.7297rem + 3.3514vw, 4.5rem);
	letter-spacing: -0.02em;
	line-height: 121%;
	.title-span {
		color: $accent-1;
	}
}

.link-text {
	color: rgb(255, 255, 255);
	&:hover {
		color: $accent-1;
	}
	&:active {
		filter: brightness(0.6);
	}
}

#open-menu-btn {
	margin: 1rem;
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2.5rem;
}

.about {
	margin: 2rem 0;
	padding: 2rem 1rem;
	justify-content: space-evenly;
	.about-title {
		flex-shrink: 0;
	}
	.about-text-card {
		max-width: 60ch;
		line-height: normal;
		box-shadow: $shadow-z2;
		padding: 1rem;
		background-color: $background-dark-secondary;
		border-radius: $border-radius;
		box-shadow: $shadow-card;
	}
	.about-links {
		margin-top: 2rem;
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		gap: 2rem;
		align-items: center;
	}
}

.projects {
	display: block;
	gap: 4rem;
	padding: 2rem 1rem 1rem;
	margin: 8rem 0;
	overflow: hidden;
}

.project-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: $shadow-z2;
	background-color: $background-dark-secondary;
	border-radius: $border-radius;

	picture {
		height: 250px;
		overflow: hidden;
		img {
			border-radius: $border-radius;
		}
	}

	.project-technology-list {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		padding: 1rem 2rem;
		width: 100%;
		font-size: 0.75rem;
		border-radius: 9999px;

		span {
			color: $accent-1;
			background: rgba($accent-1, 0.1);
			padding: 0.25rem 0.5rem;
			border-radius: $border-radius-full;
		}
	}

	.project-screenshot-container {
		max-height: 150px;
		overflow: hidden;
		margin: 0;
		width: 100%;
	}

	.project-screenshot {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
	.project-title {
		padding: 1rem 3.5rem;
		text-align: center;
	}
	.project-description {
		padding: 1rem;
	}
	.project-links {
		margin-top: auto;
		display: flex;
		width: 100%;
		padding: 1rem;
		align-items: center;
		justify-content: space-evenly;
	}
}

.contact {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(8, 69, 96, 1) 50%,
		rgba(8, 69, 96, 1) 100%
	);
	text-align: center;
	margin: 6rem 0 0;
	padding: 2rem 1rem 2rem;
	input,
	textarea {
		width: 100%;
		margin-bottom: 0.5rem;
		padding: 0.5rem;
		font-family: $main-font-family;
		font-size: inherit;
		color: $color-dark;
		background-color: $background-dark-secondary;
		border: 1px solid grey;
		border-radius: 5px;
	}
}

// media queries
@media screen and (min-width: 425px) {
	.title {
		// 1.7rem@320px, 4rem@1800px
		font-size: clamp(1.7rem, 1.2027rem + 2.4865vw, 4rem);
	}
}

@media screen and (min-width: 768px) {
	#open-menu-btn,
	#close-menu-btn {
		display: none;
	}
	.nav {
		background: linear-gradient(
			rgba(0, 0, 0, 0.9) 0%,
			rgba(0, 0, 0, 0.9) 70%,
			rgba(0, 0, 0, 0) 100%
		);
		text-shadow: 0px 0px 20px #000000;
		box-shadow: none;
		position: absolute;
		transform: translateX(0%);
		.nav-links {
			margin: 0 2vw;
			flex-direction: row;
			justify-content: end;
			gap: min(2vw, 1rem);
			align-items: start;

			:nth-child(1) {
				order: -1;
				margin: auto;
				margin-left: 0;
			}
		}
		a {
			padding: 1.5rem 2vw;
		}
	}

	.icons {
		grid-template-columns: repeat(8, 1fr);
		gap: 4vw;
	}

	.icon-container:nth-child(1n + 5) .banner-tooltip {
		top: unset;
		bottom: 110%;
	}

	.about .about-text-card {
		padding: 2rem;
	}

	.project-card {
		.project-description {
			padding: 1rem 2rem;
		}
		.project-screenshot-container {
			max-height: 250px;
		}
	}

	.project-card .project-links {
		padding: 1rem 2rem 2rem;
	}
}

@media screen and (min-width: 992px) {
	.about {
		flex-direction: row;
		justify-content: center;
		gap: 5%;
		.about-links {
			gap: 1.5rem;
			margin: 0;
		}
	}
}
